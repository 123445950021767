import React from 'react';
import './GuideList.css';

class GuideCard extends React.Component {

  onGuideClick = () => {
    const { guide, onGuideSelected } = this.props;
    if (onGuideSelected) onGuideSelected(guide);
  }

  render = () => {
    const { guide } = this.props;

    return (
      <div key={guide.id} className="guide-description" onClick={this.onGuideClick}>
        <img src={guide.avatar_url} alt={guide.name} className="guide-image" />
        <div>
          <p className="guide-name">{guide.name}</p>
          {guide.is_guided_countries_verified && (
            <div className="tooltip">
              <i className="fas fa-check verified-icon" />
              <span className="tooltiptext">Guided countries verified</span>
            </div>
          )}
          <p className="guide-email">{guide.email}</p>
        </div>
      </div>
    )
  }

}

export default GuideCard;