import React from 'react';
import Button from '@exploreshare/everest/base/Button';
import Link from '@exploreshare/everest/base/Link';
import Input from '@exploreshare/everest/base/Input';
import Text from '@exploreshare/everest/base/Text';

import './LogIn.css';
import { logIn } from '../../services';

class LogIn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loading: false,
    }
  }

  submit = () => {
    const { email, password } = this.state;
    const { onLoggedIn } = this.props;

    this.setState({ loading: true, error: false })
    logIn({ email, password }).then(({ token }) => {
      if (token) {
        localStorage.setItem('es:map:token', token)
        if (onLoggedIn) onLoggedIn();
      }
    }).catch(() => {
      this.setState({ loading: false, error: true })
    })
  }

  onChange = key => e => this.setState({ [key]: e.target.value })

  render = () => {
    const { email, password, loading, error } = this.state;
    return (
      <div className="login-container">
        <div className="login-card">
          <Text size="lg">Log in using your LeadApp credentials</Text>
          <Input
            label="User"
            value={email}
            handleChange={this.onChange('email')}
            isValid
            />
          <Input
            label="Password"
            value={password}
            handleChange={this.onChange('password')}
            type="password"
            isValid
          />
          <Button
            fullWidth
            style={{ marginBottom: '1em' }}
            onClick={this.submit}
            disabled={loading}
            >
            Log In
            &nbsp;{loading && <i class="fas fa-spinner fa-spin"></i>}
          </Button>
          <Link
            fullWidth
            type="text"
            to={`${process.env.REACT_APP_LA2_URL}/leadapp/map`}>
            <i class="fa fa-magic" aria-hidden="true"></i>&nbsp;
            Or try logging in through LeadApp
            </Link>
          {error && <Text color="red" size="sm">Error logging in</Text>}
        </div>
      </div>
    )
  }

}

export default LogIn;