import React from 'react';

import { getGuides, getCountries, getProfile } from './services';
import ESMap from './components/ESMap';
import GuideList from './components/GuideList';
import GuideDetails from './components/GuideDetails';
import LogIn from './components/LogIn';

import './App.css'

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      guides: [],
      loading: true,
    };

    // Save token from query param to local storage
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    if (token) localStorage.setItem('es:map:token', token);
  }

  async componentDidMount() {
    try {
      const profile = await getProfile();
      if (profile.role=== 'sales' || profile.role === 'admin') {
        this.loadData();
        this.setState({ isLoggedIn: true, loading: false });
      }
    } catch {
      this.setState({ loading: false });
    }
  }

  loadData = () => {
    Promise.all([getCountries(), getGuides()]).then(ans => {
      const countries = ans[0];
      const guides = ans[1];
      this.setState({ countries, guides, isLoggedIn: true, loading: false });
    });
  }

  onCountrySelected = country => this.setState({ selectedCountry: country })

  onGuideSelected = guide => this.setState({ selectedGuide: guide })

  openDetails = guide => this.setState({ guideDetailed: guide })

  closeDetails = guide => this.setState({ guideDetailed: undefined })

  render() {
    const { countries, guides, selectedCountry, selectedGuide, guideDetailed, isLoggedIn, loading } = this.state;

    if (loading) return <i className="fas fa-spinner fa-spin" style={{ margin: 'auto', fontSize: '2em', marginTop: '50vh' }} />
    if (!isLoggedIn) return <LogIn onLoggedIn={this.loadData} />

    return (
    <>
        <div className="ES-map">
          <ESMap
            countries={countries}
            guides={guides}
            onCountrySelected={this.onCountrySelected}
            onGuideSelected={this.onGuideSelected}
          />
        </div>
        {guideDetailed && (
          <div className="guide-details">
            <GuideDetails
              guide={guideDetailed}
              onClose={this.closeDetails}
            />
          </div>
        )}
        <div className="guide-list">
          <GuideList
            selectedCountry={selectedCountry}
            selectedGuide={selectedGuide}
            onGuideSelected={this.openDetails}
            guides={guides}
          />
        </div>
      </>
    );
  }
}

export default App;
