import React from 'react';
import GuideCard from './GuideCard';
import './GuideList.css';

class GuideList extends React.Component {

  render = () => {
    const { selectedCountry, selectedGuide, guides, onGuideSelected } = this.props;
    if (!selectedCountry) {
      return <p className="title">Select a country or a guide...</p>
    }

    const availableGuides = guides.filter(guide => {
      const isInSelectedCountry = guide.guided_countries.findIndex(country => country._id === selectedCountry._id) >= 0;
      const isSelectedGuide = selectedGuide && guide.id === selectedGuide.id
      return isInSelectedCountry && !isSelectedGuide;
    })

    return (
      <>
        {selectedGuide && <GuideCard onGuideSelected={onGuideSelected} guide={selectedGuide} />}
        <p className="title">
          {selectedGuide ?
              <span>Other guides in <b>{selectedCountry.title}</b></span>
            : <span>Available guides in <b>{selectedCountry.title}</b></span>
          }
        </p>
        {availableGuides
          .sort((g1, g2) => {
            if (g1.is_guided_countries_verified && !g2.is_guided_countries_verified) return -1;
            if (!g1.is_guided_countries_verified && g2.is_guided_countries_verified) return 1;
            return 0;
          })
          .map(guide => <GuideCard key={guide.id} onGuideSelected={onGuideSelected} guide={guide} />)
        }
        {availableGuides.length <= 0 && <p style={{ color: '#666', textAlign: 'center' }}>Nothing here...</p>}
      </>
    )
  }

}

export default GuideList;