import React from 'react';
import  { Map, TileLayer, Marker, Popup, GeoJSON, LayersControl, LayerGroup } from 'react-leaflet';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import randomPointsOnPolygon from 'random-points-on-polygon';
import _ from 'lodash';
import countriesBorders from '../utils/countries.json';

const swapCoordinates = c => [c[1], c[0]]

class ESMap extends React.Component {

  constructor(props) {
    super(props);
    this.state = { availableGuides: [] };
    console.log('constructed')
  }

  componentDidMount() {
    this.saveAvailableGuides();
  }

  componentDidUpdate(prevProps) {
    console.log('update')
    const { guides } = this.props;
    if (!_.isEqual(prevProps.guides, guides)) {
      this.saveAvailableGuides();
    }
  }

  saveAvailableGuides = () => {
    const { guides, countries } = this.props;
    console.log('save available guides')
    console.log({guides, countries})
    const guidesInCountries = guides.reduce((memo, guide) => {
      guide.guided_countries.forEach(country => {
        if (!memo[country.title]) memo[country.title] = [];
        memo[country.title].push(guide);
      })
      return memo;
    }, {})

    const availableGuides = _.flatten(
        countries
          .map(country => {
            const guidesInCountry = guidesInCountries[country.title];
            const countryBorders = countriesBorders.features.find(c => c.properties.name === country.title)
            if (!guidesInCountry || !countryBorders) return null;
            const coordinates = randomPointsOnPolygon(guidesInCountry.length, countryBorders);
            return guidesInCountry.map((g, i) => ({ country, coordinates: coordinates[i], ...g }))
          })
          .filter(c => c)
    );
    this.setState({ availableGuides });
  }

  clickToFeature = (e) => {
    const { countries, onCountrySelected, onGuideSelected } = this.props;
    const layer = e.target;
    const clickedCountry = countries.find(c => c.title === layer.feature.properties.name);
    if (clickedCountry) {
      onGuideSelected(null)
      onCountrySelected(clickedCountry);
    }
 }

  onEachFeature = (feature, layer) => {
    layer.on({
      click: this.clickToFeature
    });
  }

  render() {
    console.log('render')
    console.log(this.state)
    const { onGuideSelected, onCountrySelected } = this.props;
    const { availableGuides } = this.state;
    const heatmapPoints = availableGuides.map(guide => swapCoordinates(guide.coordinates.geometry.coordinates));

    return (
      <Map
        style={{ width: '100%', height: '100%' }}
        animate
        center={[0.0, 0.0]}
        zoom={3}
      >

        <TileLayer
          attribution='&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <LayersControl collpased={false} position="topright">
          <LayersControl.Overlay checked name="Countries">
            <GeoJSON
              data={countriesBorders}
              onEachFeature={this.onEachFeature}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Guide markers">
            <LayerGroup>
              {availableGuides.map((guide, i) => (
                <Marker
                  key={i}
                  position={swapCoordinates(guide.coordinates.geometry.coordinates)}
                  onClick={() => {
                    onGuideSelected(guide)
                    onCountrySelected(guide.country)
                  }}
                >
                  <Popup>{guide.name}</Popup>
                </Marker>
              ))}
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Heatmap">
            <HeatmapLayer
              fitBoundsOnLoad
              fitBoundsOnUpdate
              points={heatmapPoints}
              longitudeExtractor={m => m[1]}
              latitudeExtractor={m => m[0]}
              intensityExtractor={m => 30}
            />
          </LayersControl.Overlay>
        </LayersControl>
      </Map>
    );
  }
}

export default ESMap;