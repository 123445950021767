
export const basicGet = url => {
  const token = localStorage.getItem('es:map:token');
  return new  Promise(async (resolve, reject) => {
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if(response.status >=400) return reject(response.body);
    const json = await response.json();
    resolve(json);
  });
};

export const basicPost = (url, body) => {
  return new  Promise(async (resolve, reject) => {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if(response.status >=400) return reject(response.body);
    const json = await response.json();
    resolve(json);
  });
};

export const getCountries = async () => await basicGet(`${process.env.REACT_APP_API_URL}/api/v1/dashboard/places/country`);

export const getGuides = async () => await basicGet(`${process.env.REACT_APP_API_URL}/api/v1/sales/all`);

export const getGuideTrips = async guideId => await basicGet(`${process.env.REACT_APP_API_URL}/api/v1/dashboard/guides/trips/${guideId}`);

export const getProfile = async () => await basicGet(`${process.env.REACT_APP_API_URL}/api/v1/dashboard/auth/profile`);

export const logIn = async form => await basicPost(`${process.env.REACT_APP_API_URL}/api/v1/dashboard/auth/login`, form);