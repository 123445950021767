import React from 'react';

import './TripCard.css';

class TripCard extends React.Component {

  openTrip = () => {
    var win = window.open(this.props.trip.link, '_blank');
    win.focus();
  }

  render = () => {
    const { trip } = this.props

    return (
      <div className="trip-container" onClick={this.openTrip}>
        <img
          className="trip-image"
          src={trip.cover_url || 'https://www.explore-share.com/wp-content/uploads/2019/09/Logo-ES-White.png'}
          alt="trip"
        />
        <p className="trip-title">
          {trip.title}
        </p>
      </div>
    )
  }

}

export default TripCard;