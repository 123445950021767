import React from 'react';
import _ from 'lodash';
import { getGuideTrips } from '../../services';

import './GuideDetails.css';
import TripCard from './TripCard';


class GuideDetails extends React.Component {

  state = { }

  componentDidMount = () => this.updateGuideTrips(this.props.guide.id);

  componentDidUpdate = prevProps => {
    const { guide } = this.props;
    if (!_.isEqual(prevProps.guide, guide)) {
      if (guide) this.updateGuideTrips(guide.id);
    }
  }

  updateGuideTrips = async (guideId) => {
    if (guideId) {
      const trips = await getGuideTrips(guideId);
      this.setState({ trips });
    }
  }

  closeTripsList = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  }

  render = () => {
    const { guide } = this.props;
    const { trips } = this.state;
    console.log(this.props)

    if (!trips) return <div>Loading...</div>;

    return (
      <div className="container">
        <p onClick={this.closeTripsList} className="back-button">
          <i className="fa fa-angle-left"></i>&nbsp;<b>Go back to map</b>
        </p>
        <p>Trips from <b>{guide.name}</b></p>
        <div className="trip-card-container">
          {trips.map(trip => <TripCard key={trip._id} trip={trip} />)}
        </div>
      </div>
    )
  }
}

export default GuideDetails;